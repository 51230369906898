import { useEffect, useState } from 'react'

export const useEffectWithWeglot = (effect: (wglt: any) => any, deps?: React.DependencyList) => {
  const [isInitialized, setIsInitialized] = useState(false)
  const [wglt, setWglt] = useState<any>(null)
  useEffect(() => {
    if (!isInitialized) {
      ;(window as any).Weglot.initialize({
        api_key: 'wg_bada9b85bbefafbe286753a7768a63d93',
      })
      setIsInitialized(true)
      setWglt((window as any).Weglot)
      return
    }
    if (wglt) {
      return effect(wglt)
    }
  }, [isInitialized, wglt, ...(deps || [])])
}

const langs = ['en', 'fr', 'de', 'es'] as const
type Lang = (typeof langs)[number]
const toLang = (lang: string | undefined | null): Lang | null => {
  if (lang && langs.includes(lang as Lang)) {
    return lang as Lang
  }
  return null
}

const getLangBySearchParams = () => {
  const searchParams = new URLSearchParams(window.location.search)
  const searchParamsObject = Object.fromEntries(searchParams.entries())
  const langBySearchParams = toLang(searchParamsObject.lang)
  return langBySearchParams
}

export const useLangNormalizer = () => {
  // get search params
  const [lang, setLang] = useState<Lang | null>(null)
  // set lang
  useEffect(() => {
    const langBySearchParams = getLangBySearchParams()
    const langByNavigator = toLang(navigator.language.slice(0, 2))
    setLang(langBySearchParams || langByNavigator || 'en')
  }, [])
  // redirect if lang not match
  useEffect(() => {
    if (!lang) {
      return
    }
    const langBySearchParams = getLangBySearchParams()
    if (langBySearchParams !== lang) {
      const searchParams = new URLSearchParams(window.location.search)
      searchParams.set('lang', lang)
      window.location.search = searchParams.toString()
    }
  }, [lang])
  // set weglot by lang
  useEffectWithWeglot(
    (wglt) => {
      if (!lang) {
        return
      }
      wglt.switchTo(lang)
      setTimeout(() => {
        document.body.classList.add(`lang-${lang}`)
        document.body.classList.remove('hidden')
      }, 200)
    },
    [lang]
  )
}

export const LangNormalizer = () => {
  useLangNormalizer()
  return null
}
